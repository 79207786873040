<template>
    <div>
        <v-toolbar dark color="orange">
            <v-toolbar-title v-if="datosMedico"><b>{{datosMedico.nombre}}</b><br/>{{datosMedico.especialidadNombre}}</v-toolbar-title>
            <!-- <v-toolbar-title v-if="datosMedico"><b>{{medico.nombre}}</b><br/>{{medico.especialidadNombre}}</v-toolbar-title> -->
            <v-spacer/>

            <v-fade-transition>
                <v-btn @click='tipoCal="cal"' color="orange" v-if="datosMedico.nombre && tipoCal == 'turnos'">
                    <v-icon>mdi-calendar</v-icon>
                    Calendario
                </v-btn>
            </v-fade-transition>

            <v-fade-transition>
                <v-btn @click='tipoCal="turnos"'  color="orange" v-if="datosMedico.nombre && (tipoCal == 'cal'||tipoCal == 'detalles')">
                    <v-icon>mdi-account-multiple</v-icon>
                    Turnos
                </v-btn>
            </v-fade-transition>


            <v-fade-transition>
                <v-btn  @click="dialog=true" color="orange" v-if="tipoCal=='cal'">
                    <v-icon>mdi-account-search</v-icon>
                    Médicos
                </v-btn>
            </v-fade-transition>


        </v-toolbar>

        <calendario
            v-if='tipoCal=="cal"'
            :medico="med"
            :datosMedico.sync="datosMedico"
            :fecha.sync="estaFecha"
        />

        <turnos
            v-if='tipoCal=="turnos"'
            :medico="med"
            :estaConsulta.sync="estaConsulta"
            :espNombre=datosMedico.especialidadNombre
            @consultaSeleccionada="cargaEstaConsulta"
        />

        <atencion
            v-if='tipoCal=="detalles"'
            :medico="med"
            :estaConsultaDatos="estaConsultaDatos"
        />

        <v-dialog
            height="500px"
            width="300px"
            v-model="dialog"
        >
            <v-sheet>
                <lista-medicos
                    :medico.sync="medico"
                />
            </v-sheet>
        </v-dialog>
    </div>

</template>

<script>
export default {
    components:{
        'lista-medicos':()=>import('@/components/consulta/ListadoMedicos'),
        'calendario':()=>import('@/views/consulta/ConsultaCalendario'),
        'turnos':()=>import('@/views/consulta/ConsultaTurnos'),
        'atencion':()=>import('@/views/consulta/ConsultaAtencion'),
    },
    data:()=>({
        medico:[],
        med:0,
        dialog:false,
        datosMedico:{},
        estaConsulta:null,
        estaConsultaDatos:{},

        estaFecha:"",
        tipoCal:"cal",
    }),
    methods:{
        async cargaEstaConsulta(id){
            try{
                const req = await this.$http({
                    method:"GET",
                    url:'/consulta/getCita',
                    params:{id:id}
                })
                if(req.data.estado == "true"){
                    this.estaConsultaDatos=req.data.root
                    this.tipoCal="detalles"
                }

            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        }
    },
    watch:{
        medico(v){
            this.dialog=false
            this.med=v[0]
        },
        estaConsulta(val){
            this.cargaEstaConsulta(val.id)
        },
    },


}
</script>

<style>

</style>